:root {
}

/* .landing_hero_title {
	font-size: var(--app_title_font_size);
	font-weight: 900;
} */

.landing_hero_container {
	background-color: white;
	box-shadow: var(--section_shadow);
}

.landing_hero {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8vh 2vw;
	max-width: 1200px;
	margin: 0 auto;
}

.hero_heading_container {
	width: 40%;
	margin: auto 0;
}

.hero_image_container {
	width: 48%;
}

.highlight {
	color: var(--col_medic_highlight);
}

.highlight_under {
	/* border-bottom: solid 2px var(--col_medic_highlight); */
}
