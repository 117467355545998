:root {
	--app_title_font_size: 2.5rem;
	--shadow_transparency: 0.25;

	--col_blue_1: #47a4ff;
	--col_blue_2: #3a3eff;
	--col_green_1: #54cc85;
	--col_green_2: #36bf6d;
	--col_orange_1: #fd9c00;
	--col_orange_2: #fd7800;
	--col_purple_1: #8e50ff;
	--col_purple_2: #6200ea;
	--col_blue_3: #4169e1;
	--col_medic_highlight: var(--col_green_2);
	--col_medic_highlight_shadow: rgba(84, 204, 133, var(--shadow_transparency));
	--col_clinic_highlight: var(--col_blue_2);
	--col_clinic_highlight_shadow: rgba(58, 62, 255, var(--shadow_transparency));
	--font_color_dark: #222;
	--font_color_mid: #444;
	--font_color_low: #666;
	--section_shadow: 0 6px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

* {
	max-width: 100%;
	max-height: 100%;
	box-sizing: border-box;
}

.app_container {
	background-color: #fffafa;
	margin-top: 3rem;
	color: var(--font_color_dark);
}

.app_navbar {
	height: 3rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* background-color: #fffafa; */
	background-image: linear-gradient(to bottom, #fffafa, #fffafabf);
	backdrop-filter: blur(4px);

	/* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
	box-shadow: var(--section_shadow);
}

.route_container {
	max-width: 1200px;
	margin: 0 auto;
}

.landing_hero_title {
	font-size: var(--app_title_font_size);
	line-height: 1.2em;
	font-weight: 900;
}

.navlink {
	padding: 6px 12px;
}

.hero_cta_explainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 1rem 0;
}

.hero_cta_explainer_item {
	/* height: 9rem; */
	width: 48%;
	margin-right: 8px;
	padding: 10px 16px;
	/* border: solid 0.5px #222; */
	border-radius: 12px 12px 0 12px;
	box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.1),
		0 2px 6px -2px rgba(0, 0, 0, 0.05);
}

.hero_cta_explainer_item_fullw {
	width: max-content;
	margin: 2vh 0 0;
}

.hero_cta_explainer_item:hover {
	/* border-bottom: solid 2px var(--col_medic_highlight); */
	box-shadow: 0 10px 15px -3px var(--col_medic_highlight_shadow);
}

.hero_cta_explainer_item_title {
	font-size: 18px;
	font-weight: 600;
	color: var(--font_color_mid);
	margin: 8px 0;
}

.hero_cta_explainer_item_content {
	font-size: 14px;
	line-height: 1.2em;
	color: var(--font_color_low);
}

.hero_cta_explainer_item_btn {
	margin: 16px 0 6px;
	color: var(--col_medic_highlight);
	font-weight: 600;
	font-style: underline;
}

.hero_cta_explainer_item_btn:hover > a {
	border-bottom: solid 2px var(--col_medic_highlight);
}

/* 
.app {
	text-align: center;
	background-color: #2d3748;
}

.app_logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.app_logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.app_header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.test {
	color: #282c34;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	align-items: center;
	justify-content: space-between;
	margin: 1rem 8px;
}

.app_link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
} */
